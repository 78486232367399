var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("Help")]),
      _c("div", { staticClass: "filter-container" }, [
        _c("div", { staticClass: "filter" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "span",
                { staticClass: "input-group-text text-white-50 bg-primary" },
                [_c("BIconSearch")],
                1
              )
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "search", placeholder: "Filter videos" },
              domProps: { value: _vm.filter },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filter = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _vm.filteredVideos.length
        ? _c(
            "div",
            { staticClass: "card-deck" },
            _vm._l(_vm.filteredVideos, function(video, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass: "card bg-primary",
                  attrs: { title: video.title }
                },
                [
                  video.type === "youtube"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "card-img-top embed-responsive embed-responsive-16by9"
                        },
                        [
                          _c("YoutubeReplay", {
                            attrs: {
                              id: video.id,
                              autoplay: false,
                              "player-vars": _vm.playerVars
                            }
                          })
                        ],
                        1
                      )
                    : video.type === "image"
                    ? _c("img", {
                        staticClass: "card-img-top",
                        attrs: { src: video.url, alt: "video.title" }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "card-body" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(video.title))
                    ]),
                    video.text
                      ? _c("p", { staticClass: "card-text" }, [
                          _vm._v(_vm._s(video.text))
                        ])
                      : _vm._e()
                  ])
                ]
              )
            }),
            0
          )
        : _c("div", [_c("InfoAlert", [_vm._v("No matching videos!")])], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }